// import './App.css';
import './index.css';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from './firebase-config';
import { useState, lazy, Suspense} from 'react';

// import CreatePost from './pages/CreatePost';
// import Login from './pages/Login';
// import About from './pages/About';
// import Post from './pages/Post';
// import Home from './pages/Home';


const Home = lazy(() => import('./pages/Home'))
const Post = lazy(() => import('./pages/Post'))
const CreatePost = lazy(() => import('./pages/CreatePost'))
const Login = lazy(() => import('./pages/Login'))
const About = lazy(() => import('./pages/About'))


function App() {
  const [isAuth, setIsAuth] = useState(localStorage.getItem('isAuth'));

  const signUserOut = () => {
    signOut(auth).then(() =>{
      localStorage.clear();
      setIsAuth(false);
      window.location.pathname="/";
    });
  }
  return (
    <Suspense fallback={ <div className="loader"></div>}> 

    <BrowserRouter>
    
      <nav>
        <div className='siteTitle'>
          SnippetShare
        </div>

        
        {!isAuth ? 
        <>
        <Link to="/about">About</Link> 
        <Link to="/">Login</Link> 
        </>:
        <>
            <Link to="/about">About</Link> 
          <a><Link to="/home">Home</Link></a>
          <a><Link to="/createpost">Create Post</Link></a>
          <a><button className='button4' onClick={signUserOut}>Log Out</button></a>
          </>
        }
      </nav>
    <Routes>
      <Route exact path="/" element={<Login setIsAuth={setIsAuth}/>}/>
      <Route path="/home" element={<Home isAuth={isAuth}/>}/>
      <Route path="/createpost" element={<CreatePost isAuth={isAuth}/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/post/:postId" element={<Post/>}/>
      <Route path="*" element={<About />} />
    </Routes>
    </BrowserRouter>
    </Suspense>

  );
}

export default App;
